<template>
  <div class="space-y-10">
    <!-- pageTitle -->
    <div class="pageTitle">
      <h1>Wish List</h1>
      <!-- <h3>Please have a look at our partners and packages below.</h3> -->
    </div>
    <!-- content -->
    <div class="grid grid-cols-2 gap-12">
      <!-- wishList -->
      <div class="space-y-4" v-if="$store.state.userWishList.length">
        <h4 class="font-semibold text-lg">Your Wish List</h4>
        <ul>
          <li v-for="(item, index) in $store.state.userWishList" :key="index">
            <router-link
              class="href uppercase"
              :to="'/' + $store.state.selectedPartner.id + '/p/' + item"
            >
              {{ item }}</router-link
            >
          </li>
        </ul>
      </div>
      <!-- best sellers -->
      <div class="space-y-4" v-if="$store.state.retailPackages">
        <h4 class="font-semibold text-lg">Our Best Selling Packages</h4>
        <ul>
          <li v-for="(item, index) in $store.state.retailPackages" :key="index">
            <router-link
              :to="
                '/' + $store.state.selectedPartner.id + '/p/' + item.packageCode
              "
              class="href"
            >
              <span class="uppercase underline">{{ item.packageCode }}</span> -
              <span>{{ item.destination.slice(0, 50) }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>